import axios from 'axios'

class Aetheryte {
    constructor (outputJp, outputEn, outputKo, size) {
      this.outputJp = outputJp
      this.outputEn = outputEn
      this.outputKo = outputKo
      this.size = size
    }
}

function aetheryteSearch (str) {
  var jsonItems = []
  // 日付をYYYY-MM-DDの書式で返すメソッド
  function formatDate (dt) {
    var y = dt.getFullYear()
    var m = ('00' + (dt.getMonth() + 1)).slice(-2)
    var d = ('00' + dt.getDate()).slice(-2)
    return (y + '-' + m + '-' + d)
  }
  var targetDate = new Date()
  targetDate = formatDate(new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate() - 2))
  console.log('targetDate:' + targetDate)
  // console.log(targetDate)
    axios.get(`../../../aetheryte.json?timestamp=${targetDate}`).then(res => {
      res.data.forEach(data => {
        jsonItems.push(data)
      })
    })
  // console.log(jsonItems)
  // エリア判定
    var text = str
    text = text.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) { // 半角化
      return String.fromCharCode(s.charCodeAt(0) - 65248)
    })
    // console.log('半角化:' + text)
    text = text.toLowerCase()// 小文字化
    // console.log('小文字化:' + text)
    text = text.replace(/\r?\n/g, '')// 改行排除
    // console.log('改行排除:' + text)
    text = text.replace('goblin', '')
    // console.log('ゴブリン削除:' + text)
  var result = ''
    // output初期化
    var outputJp = ''
    var outputEn = ''
    var outputKo = ''
    var AreaNameEn = ''
    var size = ''
  console.log('text:' + text)
  if (text.match('ゴブ.*|ｺﾞﾌﾞ.*|ごぶ.*|gob.*|하늘잔.*|.*ナナモ.*|.*ﾅﾅﾓ.*|.*sultana.*|.*나나모 대풍차.*|.*나나모대풍차.*') != null) { // ゴブ
      result = text.match('ゴブ.*|ｺﾞﾌﾞ.*|ごぶ.*|gob.*|하늘잔.*|.*ナナモ.*|.*ﾅﾅﾓ.*|.*sultana.*|.*나나모 대풍차.*|.*나나모대풍차.*')
      console.log(result[0])
      AreaNameEn = 'The Goblet'
    } else if (text.match('ミスト.*|ﾐｽﾄ.*|みすと.*|mist.*|안갯빛.*|.*トップマ.*|.*ﾄｯﾌﾟﾏ.*|.*topmast.*|.*중층 돛대.*|.*중층돛대.*') != null) { // ミスト
      result = text.match('ミスト.*|ﾐｽﾄ.*|みすと.*|mist.*|안갯빛.*|.*トップマ.*|.*ﾄｯﾌﾟﾏ.*|.*topmast.*|.*중층 돛대.*|.*중층돛대.*')
      console.log(result[0])
      AreaNameEn = 'Mist'
    } else if (text.match('ラベ.*|ﾗﾍﾞ.*|らべ.*|lav.*|lb.*|라벤더.*|.*リリーヒ.*|.*ﾘﾘｰﾋ.*|.*lily.*|.*백합 언덕.*|.*백합언덕.*') != null) { // ラベ
      result = text.match('ラベ.*|ﾗﾍﾞ.*|らべ.*|lav.*|lb.*|라벤더.*|.*リリーヒ.*|.*ﾘﾘｰﾋ.*|.*lily.*|.*백합 언덕.*|.*백합언덕.*')
      console.log(result[0])
      AreaNameEn = 'The Lavender Beds'
    } else if (text.match('シロガネ.*|ｼﾛｶﾞﾈ.*|しろがね.*|白金.*|白銀.*|shiro.*|시로가네.*|.*紅梅.*|.*kobai.*|.*홍매전.*') != null) { // シロガネ
      result = text.match('シロガネ.*|ｼﾛｶﾞﾈ.*|しろがね.*|白金.*|白銀.*|shiro.*|시로가네.*|.*紅梅.*|.*kobai.*|.*홍매전.*')
      console.log(result[0])
      AreaNameEn = 'Shirogane'
    }
    console.log(AreaNameEn)
  // 番地取得※全角対応
    if (result !== '') {
    var prots = result[0].match(/[0-9]+\.?[0-9]*/g)
    console.log('prots:' + prots)
 }
  console.log(jsonItems)
  // アパルトメント判定
    if (text.match('.*アパル.*|.*ｱﾊﾟﾙ.*|.*ナナモ.*|.*ﾅﾅﾓ.*|.*リリーヒ.*|.*ﾘﾘｰﾋ.*|.*トップマ.*|.*ﾄｯﾌﾟﾏ.*|.*紅梅.*|.*apart.*|.*lily.*|.*topmast.*|.*sultana.*|.*kobai.*|.*아파트.*|.*백합 언덕.*|.*백합언덕.*|.*중층 돛대.*|.*중층돛대.*|.*나나모 대풍차.*|.*나나모대풍차.*|.*홍매전.*') != null) {
      size = 'apartment'
      switch (AreaNameEn) {
        case 'The Goblet':
          if (text.match('.*裏.*|.*2号棟.*|.*2号館.*|.*拡張.*|.*Subdivision.*|.*확장 구역.*') != null) {
            outputJp = '［拡張街］ナナモ大風車'
            outputEn = 'The Sultana\'s Breath Subdivision.'
            outputKo = '[확장 구역] 나나모 대풍차'
          } else {
            outputJp = 'ナナモ大風車'
            outputEn = 'The Sultana\'s Breath'
            outputKo = '나나모 대풍차'
          }
          break
        case 'Mist':
          if (text.match('.*裏.*|.*2号棟.*|.*2号館.*|.*拡張.*|.*Subdivision.*|.*확장 구역.*') != null) {
            outputJp = '［拡張街］トップマスト'
            outputEn = 'The Topmast Subdivision.'
            outputKo = '[확장 구역] 중층 돛대'
          } else {
            outputJp = 'トップマスト'
            outputEn = 'The Topmast.'
            outputKo = '중층 돛대'
          }
          break
        case 'The Lavender Beds':
          if (text.match('.*裏.*|.*2号棟.*|.*2号館.*|.*拡張.*|.*Subdivision.*|.*확장 구역.*') != null) {
            outputJp = '［拡張街］リリーヒルズ'
            outputEn = 'Lily Hills Subdivision.'
            outputKo = '[확장 구역] 백합 언덕'
          } else {
            outputJp = 'リリーヒルズ'
            outputEn = 'Lily Hills.'
            outputKo = '백합 언덕'
          }
          break
        case 'Shirogane':
          if (text.match('.*裏.*|.*2号棟.*|.*2号館.*|.*拡張.*|.*Subdivision.*|.*확장 구역.*') != null) {
            outputJp = '［拡張街］紅梅御殿'
            outputEn = 'Kobai Goten Subdivision.'
            outputKo = '[확장 구역] 홍매전'
          } else {
            outputJp = '紅梅御殿'
            outputEn = 'Kobai Goten.'
            outputKo = '홍매전'
          }
          break
      }
    } else {
      for (var key of Object.keys(jsonItems)) {
        // 連想配列のキーと配列の値が一致するか検索
        // console.log(jsonItems[key])
        // console.log(jsonItems[key].AreaNameEn)
        if (jsonItems[key].AreaNameEn === AreaNameEn) {
          if (jsonItems[key].Prot === prots[1]) {
            console.log(jsonItems[key].AetheryteJp)
            console.log(jsonItems[key].AetheryteEn)
            console.log(jsonItems[key].AetheryteKo)
            outputJp = jsonItems[key].AetheryteJp
            outputEn = jsonItems[key].AetheryteEn
            outputKo = jsonItems[key].AetheryteKo
            size = jsonItems[key].Size
            if (text.match('.*個室.*|.*号室.*|.*room.*|.*개인실.*') != null) {
              size = 'FC private room'
            }
          }
        }
      }
    }
  var output = new Aetheryte(outputJp, outputEn, outputKo, size)
  console.log('outputJP:' + outputJp)

return outputJp
}

// 関数をエクスポートします。
export default {
  aetheryteSearch,
}
